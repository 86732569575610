export const LS_FIND_PATH = "/find";
export const LS_AVG_DELIEVERY_TIME_STATS_PATH = "/avg-delivery-time-stats"
export const LS_DELIVERY_STATUES_STATS_PATH = "/delivery-statuses-stats";
export const LS_COMPLETED_DELIVERIES_STATS_PATH = "/completed-deliveries-stats";
export const LS_COMPLETED_DELIVERIES_MONTHLY_STATS_PATH = "/completed-deliveries-monthly-stats";
export const LS_COMPLETED_DELIVERIES_YEARLY_PARTNER_STATS_PATH = "/completed-deliveries-yearly-partner-stats";
export const LS_ORDERS_BY_MONTH_STATS_PATH = "/get-orders-by-month";
export const LS_AVERAGE_ORDER_COSTS_STATS_PATH = "/get-average-order-costs";
export const KEYCLOAK_TOKEN_FORGOT_PASSWORD_PATH = "/forgot-password";
export const KEYCLOAK_TOKEN_RESET_PASSWORD_PATH = "/reset-password";
export const LS_TRACKING_PATH = "/tracking";
export const LS_LOGISTICS_PATH = "/logistics";
export const KEYCLOAK_TOKEN_INVALIDATION_PATH = "/logout";
export const KEYCLOAK_TOKEN_LOGIN_PATH = "/login";
export const KEYCLOAK_TOKEN_REFRESH_PATH = "/refresh-token";
export const LS_MAP_MARKERS_PATH = "/get-map-markers";
export const STATUS_CODE_SUCCESS = 200; // success
export const STATUS_CODE_ACCESS_TOKEN_REFRESH_SUCCESS = 201; // success
export const STATUS_CODE_NO_SIDE_EFFECTS = 205; // return the data untouched in such scenarios
export const STATUS_CODE_AUTH_LOGIN_CREDENTIALS_INVALID = 601; // Auth login credentials invalid
export const STATUS_CODE_AUTH_REFRESH_TOKEN_EXPIRED = 602; // Refresh token expired
export const STATUS_CODE_UNKNOWN_ERROR = 605;
export const STATUS_CODE_AUTH_LOGIN_ERROR = 603;
export const STATUS_CODE_AUTH_FORGOT_PASSWORD_ERROR = 604;
export const STATUS_CODE_AUTH_RESET_PASSWORD_ERROR = 606;
export const LOGIN_STATE = {
    yes : 'yes',
    maybe : 'maybe',
    no : 'no'
}
export const DELIVERY_PARTNERS_COLOR = {
    lalamove: "#e96221",
    borzo: "#0148ff",
    rpx: "#eb1d4d",
    ahamove: "#fe5f00"
}

export const COUNTRY_COLOR_CODES = {
    ID: "#e1d6f7",
    PH: "#cce1f3",
    MY: "#f5e9ce",
    TH: "#ff0000"
}

export const CURRENCY_CODES = {
    ID: 'IDR',
    MY: 'MYR',
    PH: 'PHP',
    VN: 'VND',
    TH: 'THB'
};
export const LS_DISTINCT_SEARCH_FILTERS_PATH = "/get-search-filters";
export const LS_DRIVER_COORDINATES_PATH = "/get-driver-coordinates";
export const HERE_MAPS_KILLI = "LTNcoOE0B8mmYCBe3o5wmVxBKJLcgdBKed8ZyTXWLus";